export enum Routes {
  Home = 'index',
  Login = 'auth-login',
  Account = 'account',
  Top = 'top',
  FAQ = 'faq',
  Tournament = 'tournament',
  TournamentRatings = 'tournament-ratings',
  TournamentStats = 'tournament-stats',
  TournamentRules = 'tournament-rules',
  JoinTeam = 'join-team',
  NotFound = 'notFound',
}
