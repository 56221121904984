import { createRouter, createWebHistory } from 'vue-router';
import auth from '@/router/middlewares/auth';
import routes from './routes';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach(auth);

export default router;
