export const localStorageHelper = () => {
  const getItemFromLocalStorage = (key: string): any => {
    try {
      return JSON.parse(localStorage.getItem(key) || '');
    } catch (evt) {
      return null;
    }
  };

  const setItemInLocalStorage = (key: string, data: any) => {
    localStorage.setItem(key, JSON.stringify(data));
  };

  return {
    getItemFromLocalStorage,
    setItemInLocalStorage,
  };
};
