import type { InjectionKey, Ref } from 'vue';

export enum Breakpoint {
  Mobile = 'MOBILE',
  Tablet = 'TABLET',
  Desktop = 'DESKTOP',
}

export enum DeviceOrientation {
  Landscape = 'LANDSCAPE',
  Portrait = 'PORTRAIT',
}

export interface ResponsiveProviderData {
  currentBreakpoint: Ref<Breakpoint>;
  currentOrientation: Ref<DeviceOrientation>;
}

export const responsiveProviderKey: InjectionKey<ResponsiveProviderData> = Symbol('responsive-provider');
