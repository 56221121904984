export const ACCESS_TOKEN = 'sagesport-client-access-token';
export const COOKIE_OPTIONS = {
  expireTimes: '7d',
  path: '/',
  domain: '',
  secure: true,
  sameSite: 'Lax',
};

export const REDDY_LOCAL_STORAGE_KEY = 'SAGE_REDDY_USER_ID';
