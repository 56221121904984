<script setup lang="ts">
import AppIcon from '@/components/App/Icon/AppIcon.vue';

const props = defineProps<{
  severity: 'info' | 'success' | 'warning' | 'error';
  text?: string;
  subtext?: string;
}>();
</script>

<template>
  <div class="base-message">
    <AppIcon
      class="base-message__icon"
      name="info"
    />

    <div class="base-message__content">
      <p v-if="props.text" class="title-14">{{ props.text }}</p>
      <p v-if="props.subtext" class="text-16">{{ props.subtext }}</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.base-message {
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  background: $clear-5;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
}

.base-message__icon {
  width: 24px;
  height: 24px;
  min-width: 24px;

  @include md {
    width: 32px;
    height: 32px;
    min-width: 32px;
  }
}

.base-message__content {
  // empty
}
</style>
