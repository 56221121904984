<script setup lang="ts">
import { computed, ref } from 'vue';
import AppIcon from '@/components/App/Icon/AppIcon.vue';
import { TournamentTheme } from '@/types/tournaments';

interface BaseSelectOption {
  label: string;
  slug: string;
}

const props = defineProps<{
  options: BaseSelectOption[];
  selectedOption: BaseSelectOption;
  theme: 'main' | 'tournament' | 'sage' | TournamentTheme;
}>();

const emits = defineEmits(['update:selectedOption', 'select']);

const currentOption = computed({
  get() {
    return props.selectedOption;
  },
  set(newValue) {
    emits('update:selectedOption', newValue);
  },
});

const optionsList = computed(() => props.options.filter((option) => option.slug !== currentOption.value.slug));

const isOpened = ref(false);

const toggleDropdown = () => {
  isOpened.value = !isOpened.value;
};

const selectOption = (payload: BaseSelectOption) => {
  currentOption.value = payload;
  emits('select', payload);
  isOpened.value = false;
};
</script>

<template>
  <div class="base-select">
    <button
      class="base-select__button title-16-light"
      :class="{
        'base-select__button--main': props.theme === 'main',
        'base-select__button--tournament': props.theme === 'tournament',
        'base-select__button--sage': props.theme === 'sage',
        'base-select__button--football-elements': props.theme === TournamentTheme.FootballElements,
      }"
      @click="toggleDropdown"
    >
      <AppIcon
        v-if="props.theme === TournamentTheme.FootballElements"
        name="ball"
        class="base-select__left-icon"
      />

      {{ currentOption.label }}

      <AppIcon
        name="arrow-down"
        class="base-select__toggle-icon"
        :class="{'base-select__toggle-icon--opened': isOpened}"
      />
    </button>

    <transition name="base-select">
      <ul
        v-if="isOpened"
        class="base-select__list"
        :class="{
          'base-select__list--main': props.theme === 'main',
          'base-select__list--tournament': props.theme === 'tournament',
          'base-select__list--sage': props.theme === 'sage',
          'base-select__list--football-elements': props.theme === TournamentTheme.FootballElements,
        }"
      >
        <li
          v-for="(option, index) in optionsList"
          :key="option.slug"
        >
          <button
            :tabindex="index + 1"
            class="base-select__item title-16-light"
            :class="{
              'base-select__item--main': props.theme === 'main',
              'base-select__item--tournament': props.theme === 'tournament',
              'base-select__item--sage': props.theme === 'sage',
              'base-select__item--football-elements': props.theme === TournamentTheme.FootballElements,
            }"
            @click="selectOption(option)"
          >
            {{ option.label }}
          </button>
        </li>
      </ul>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
.base-select {
  position: relative;
}

.base-select__button {
  position: relative;
  width: 100%;
  min-height: 48px;
  padding: 8px 40px;
  background-color: $clear-10;
  border-radius: 8px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);

  &--main {
    background-color: $main-surface-color;
  }

  &--sage {
    background-color: $lilac-150;
    text-align: left;
    padding-left: 20px;
  }

  &--football-elements {
    background-color: $blue-5;
    text-align: left;
    color: $green;
  }
}

.base-select__left-icon {
  position: absolute;
  left: 16px;
  top: 50%;
  width: 17px;
  height: 17px;
  transform: translateY(-50%);
}

.base-select__toggle-icon {
  position: absolute;
  right: 8px;
  top: 50%;
  width: 32px;
  height: 32px;
  transform: translateY(-50%);
  color: $clear-70;

  &--opened {
    transform: translateY(-50%) rotate(180deg);
  }
}

.base-select__list {
  z-index: $z-level-2;
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  width: 100%;
  background-color: $clear-10;
  border-radius: 8px;
  overflow: hidden;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);

  &--main {
    background-color: $main-surface-color;
  }

  &--sage {
    background-color: $lilac-150;
  }
}

.base-select__item {
  width: 100%;
  padding: 16px 40px;
  text-align: center;
  cursor: pointer;
  color: $clear-80;
  transition: all 250ms ease-in-out;

  &:hover,
  &:focus {
    background-color: $clear-100;
    color: $lilac-150;
  }

  &--main {
    &:hover,
    &:focus {
      background: $main-gradient;
      color: $clear-100;
    }
  }

  &--sage {
    text-align: left;
    padding-left: 20px;
    color: $clear-60;

    &:hover,
    &:focus {
      color: $clear-100;
      background-color: transparent;
    }
  }

  &--football-elements {
    text-align: left;
    color: $clear-60;
  }
}

.base-select-enter-active,
.base-select-leave-active {
  transition: opacity 300ms ease;
}

.base-select-enter,
.base-select-leave-to {
  opacity: 0;
}
</style>
