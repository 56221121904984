export default {
  tournaments: 'Tournaments',
  hallOfFame: 'Hall of Fame',
  faq: 'FAQ',
  predictions: 'Predictions',
  ratings: 'Ratings',
  stats: 'Stats',
  rules: 'Rules',
  watch: 'Watch',
  titlePredictions: 'Predictions',
  player: 'Player',
  team: 'Team',
  goals: 'goals',
  saveButtonText: 'Save',
  totalPoints: 'Total points',
  roundPoints: 'Points in the round',
  modalGroupCta: 'Please join a group!',
  modalInvalidExactScore: 'Please fill in the second field of the match to save the forecast.',
  modalChooseTitle: 'Please select 8 teams!',
  modalChooseMostProductiveTeam: 'Please select your favorite team!',
  okText: 'Ok',
  rightPredictionText: 'You guessed right!',
  rightPredictionTextExcellent: 'Excellent result!',
  rightPredictionTextGood: 'Not bad! You\'re great!',
  rightPredictionTextNotEnough: 'Next time lucky!',
  rightPredictionPoints: 'points',
  rightPredictionMaxPoints: 'points',
  loosPredictionText: 'Better luck next time',
  loosPredictionPoints: 'points',
  points: 'points',
  final: 'Final',
  tournamentsFeedTitle: 'Available tournaments',
  baseMessageInitialText: 'Message text',
  statsPreviewText: 'You can view detailed statistics by',
  byLink: 'links',
  goHome: 'Go home',
  reload: 'Reload',
  pageNotFoundText: 'Page not found',
  participants: 'Participants',
  allParticipants: 'All Participants',
  tournamentLoser: 'Tournament sucker',
  allStagesRating: 'All stages',
  apply: 'Apply',
  rounds: 'Rounds',
  yes: 'Yes',
  no: 'No',
  super: 'Super!',
  divideToGroupButtonText: 'Join',
  ctaDividerText: 'Find out which group you’ve been assigned to and get predicting!',
  feCtaDividerText: 'Time to find out your element and start posting predictions!',
  videoErrorText: 'Your browser does not support embedded videos.(',
  randomizerText: 'May fortune be on your side!',
  successDivideInGroupText: 'You’ve been assigned a group!',
  feSuccessDivideInGroupText: 'Your element is',
  timerToDivideInTeamText: 'You’ll be assigned a team in',
  waitToDivideInTeamText: 'You’ll be assigned a team soon',
  ctaDivideTextPart1: 'Are you ready to follow the path of the legendary masters?',
  ctaDivideTextPart2: 'Define your coaching style!',
  ctaDivideTextPart1Fe: 'Who are you, warrior?',
  ctaDivideTextPart2Fe: 'Find out what football element burns in your heart and go to victories!',
  teamChat: 'Team chat',
  predictionTimerText: 'The first match will start in:',
  timerToStartTournamentText: 'Tournament will start in:',
  defaultErrorText: 'Something went wrong...',
  change: 'Change',
  predictionTimerOut: 'You’re out of time. You can no longer make/change predictions.',
  predictionEmptyTimer: 'Predictions will be available when the start date of the tournament appears.',
  groupStageTimerText: 'Groups stage will start in:',
  groupStageFinishTimerText: 'Groups stage will finished in:',
  playOffStageTimerText: 'The first match in the Round {name} will start in:',
  playOffFinalTimerText: 'The final will start in:',
  sages: 'sages',
  elements: 'Elements',
  signIn: 'Sign In',
  requiredField: 'Required field',
  wrongOtp: 'The code was entered incorrectly. Check the code in Reddy and enter again.',
};
