<script setup lang="ts">
import { computed } from 'vue';

interface BaseSelectTab {
  label: string;
  slug: string;
  disabled?: boolean;
}

const props = defineProps<{
  tabs: BaseSelectTab[];
  currentTab: BaseSelectTab;
}>();

const emits = defineEmits(['update:currentTab', 'select']);

const currentOption = computed({
  get() {
    return props.currentTab;
  },
  set(newValue) {
    emits('update:currentTab', newValue);
  },
});

const selectTab = (payload: BaseSelectTab) => {
  currentOption.value = payload;
  emits('select', payload);
};
</script>

<template>
  <div class="base-tabs">
    <ul class="base-tabs__list">
      <li
        v-for="tab of props.tabs"
        :key="tab.slug"
      >
        <button
          class="base-tabs__item title-12-light"
          :class="{'base-tabs__item--current': tab.slug === currentOption.slug}"
          :disabled="tab.disabled"
          @click="selectTab(tab)"
        >
          {{tab.label}}
        </button>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.base-tabs {
  width: fit-content;
  padding: 8px;
  border-radius: 12px;
  background: $clear-1;
  -webkit-backdrop-filter: blur(250px);
  backdrop-filter: blur(250px);
}

.base-tabs__list {
  display: flex;
  align-items: center;
  gap: 4px;
}

.base-tabs__item {
  width: 100%;
  min-width: 124px;
  padding: 8px 20px;
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
  background-color: $clear-10;
  color: $clear-80;
  transition: all 300ms ease;

  &:not([disabled]) {
    &:hover,
    &:focus {
      background-color: $clear-100;
      color: $lilac-150;
    }
  }

  &:disabled {
    background-color: $clear-5;
    color: $clear-10;
    cursor: default;
  }

  &--current {
    background-color: $clear-100;
    color: $lilac-150;
  }
}
</style>
