<script setup lang="ts">
import BaseToast from '@/components/Base/Toast/BaseToast.vue';
import Toast from 'primevue/toast';
import { useResponsive } from '@/composables/useResponsive';
import { Breakpoint } from '@/types/responsive';
import { computed } from 'vue';

const { currentBreakpoint } = useResponsive();

const toastPosition = computed(() => {
  switch (currentBreakpoint.value) {
    case Breakpoint.Desktop:
      return 'bottom-right';
    case Breakpoint.Tablet:
      return 'bottom-right';
    default:
      return 'bottom-center';
  }
});
</script>

<template>
  <Toast
    :position="toastPosition"
    class="base-toast-wrapper"
  >
    <template #container="{ message }">
      <BaseToast
        :type="message.severity"
        :title="message.summary"
      />
    </template>
  </Toast>
</template>

<style lang="scss">
.base-toast-wrapper {
  transform: translateX(-50%);

  @include md {
    width: auto;
    transform: translateX(0%);
  }
}
</style>
