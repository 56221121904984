import apiWrapper from '@/helpers/apiWrapper';
import type { CreateUserTeamParams, IUserTeam } from '@/types/user-teams';

export default {
  create: (params: CreateUserTeamParams) => apiWrapper.post('/api/v1/teams', params),
  show: (id: string) => apiWrapper.get<{ team: IUserTeam }>(`/api/v1/teams/${id}`),
  join: (id: string) => apiWrapper.post<{ data: boolean }>(`/api/v1/teams/${id}`),
  delete: (id: string) => apiWrapper.delete<{ data: boolean }>(`/api/v1/teams/${id}`),
  exit: (id: string) => apiWrapper.delete<{ data: boolean }>(`/api/v1/teams/${id}/user`),
};
