import type { IGroupDivided } from '@/types/groups';

export enum DivideStepEnum {
  Initial = 'INITIAL',
  InGroup = 'IN_GROUP',
  InGroupAndInTeam = 'IN_GROUP_AND_IN_TEAM',
}

export interface IUserMailingPayload {
  has_mailing: 1 | 0;
}

export interface IUserMailingResponse {
  data: boolean;
}

export interface IUserStatistics {
  match: number;
  group: number;
  scorer: number;
  keeper: number;
}

export interface IUserRanking {
  basket_place: number;
  basket_position: number;
  basket_user_count: number;
  group_position: number;
}

export interface IUserStatisticsResponse {
  data: IUserStatistics;
}

export interface IUserRankingResponse {
  basket_place: number;
  basket_position: number;
  basket_user_count: number;
  group_position: number;
}

export interface IUserDivideToGroupParams {
  tournament_id: number;
}

export interface IUserDivideToGroupResponse {
  data: IGroupDivided;
}
