import { isAxiosError } from 'axios';
import type { IError } from '@/types/api';

type ErrorWithMessage = {
  message: string
};

function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  return (
    typeof error === 'object'
    && error !== null
    && 'message' in error
    && typeof (error as Record<string, unknown>).message === 'string'
  );
}

function toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
  if (isErrorWithMessage(maybeError)) return maybeError;

  try {
    return new Error(JSON.stringify(maybeError));
  } catch {
    return new Error(String(maybeError));
  }
}

export function getError(error: unknown): IError {
  return isAxiosError(error) ? error.response?.data : { message: toErrorWithMessage(error).message, errors: null };
}
