import apiWrapper from '@/helpers/apiWrapper';
import type {
  ILoginData,
  ILoginResponse,
  IMeResponse,
  ISendCodeData,
  ISendCodeResponse,
} from '@/types/auth';

export default {
  sendCode: (payload: ISendCodeData) => apiWrapper.post<ISendCodeResponse>('/api/v1/auth/send-code', payload),
  login: (payload: ILoginData) => apiWrapper.post<ILoginResponse>('/api/v1/auth/login', payload),
  me: () => apiWrapper.get<IMeResponse>('/api/v1/auth/user'),
};
