import apiWrapper from '@/helpers/apiWrapper';
import type { IGroupRating } from '@/types/groups';
import type {
  IRatingGetFilterResponse,
  IRatingGetParams,
  IRatingGetResponse,
  IRatingUserGetParams,
  IRatingUserGetResponse,
} from '@/types/users-rating';

export default {
  // eslint-disable-next-line max-len,vue/max-len
  get: (tournamentSlug: string, params: IRatingGetParams) => apiWrapper.get<IRatingGetResponse>(`/api/v1/${tournamentSlug}/ratings`, { params }),
  groups: (tournamentSlug: string) => apiWrapper.get<{ groups: IGroupRating[] }>(`/api/v1/${tournamentSlug}/ratings/groups`),
  // eslint-disable-next-line max-len,vue/max-len
  usersByBasket: (tournamentSlug: string, basket_id: number, params: IRatingUserGetParams) => apiWrapper.get<IRatingUserGetResponse>(`/api/v1/${tournamentSlug}/ratings/basket/${basket_id}`, { params }),
  pinnedUser: (tournamentSlug: string, id: number) => apiWrapper.post<{ data: boolean }>(`/api/v1/${tournamentSlug}/ratings/${id}`),
  unpinnedUser: (tournamentSlug: string, id: number) => apiWrapper.delete<{ data: boolean }>(`/api/v1/${tournamentSlug}/ratings/${id}`),
  getFilter: (tournamentSlug: string) => apiWrapper.get<IRatingGetFilterResponse>(`/api/v1/${tournamentSlug}/ratings/filter`),
};
