import { createI18n } from 'vue-i18n';
import { localStorageHelper } from '@/helpers/localStorage';
import en from '@/locales/en';
import ru from '@/locales/ru';
import { LocalesEnum } from '@/types/locales';

export const getLocale = (): LocalesEnum => {
  const storage = localStorageHelper();
  const storageLanguage = storage.getItemFromLocalStorage(import.meta.env.VITE_STORAGE_KEY_LOCALE);

  if (storageLanguage && Object.values(LocalesEnum).includes(storageLanguage)) {
    return storageLanguage;
  }

  return import.meta.env.VITE_DEFAULT_LOCALE;
};

export default createI18n({
  locale: getLocale(),
  fallbackLocale: import.meta.env.VITE_FALLBACK_LOCALE,
  legacy: false,
  messages: {
    en,
    ru,
  },
});
