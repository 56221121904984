import auth from '@/api/auth';
import tournaments from '@/api/tournaments';
import tournament from '@/api/tournament';
import predictions from '@/api/predictions';
import userTeams from '@/api/userTeams';
import faq from '@/api/faq';
import ratings from '@/api/ratings';
import user from '@/api/user';
import winners from '@/api/winners';

export default {
  auth,
  tournaments,
  tournament,
  predictions,
  userTeams,
  faq,
  ratings,
  user,
  winners,
};
