<script setup lang="ts">
const props = defineProps<{
  size?: 's' | 'm' | 'l';
}>();
</script>

<template>
  <div
    class="base-loader"
    :class="{
      'base-loader--size-m': props.size === 'm',
      'base-loader--size-l': props.size === 'l',
    }"
  />
</template>

<style lang="scss" scoped>
.base-loader {
  border: 4px solid $clear-50;
  border-radius: 50%;
  border-top-color: $clear-90;
  width: 40px;
  height: 40px;
  animation: spin 1s infinite linear;

  &--size-m {
    border: 6px solid $clear-50;
    border-top-color: $clear-90;
    width: 60px;
    height: 60px;
  }

  &--size-l {
    border: 8px solid $clear-50;
    border-top-color: $clear-90;
    width: 80px;
    height: 80px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
