<script setup lang="ts">
const props = defineProps<{
  isActive: boolean;
}>();

const emits = defineEmits(['click']);

const handleClick = () => {
  emits('click');
};
</script>

<template>
  <button
    class="burger-button"
    @click="handleClick"
  >
    <div
      class="burger-button__icon"
      :class="{'burger-button__icon--active': props.isActive}"
    />
  </button>
</template>

<style lang="scss" scoped>
.burger-button {
  --time: 0.1s;

  --width: 40px;
  --height: 30px;
  --safe-padding-x: 10px;

  --line-height: 4px;
  --spacing: 8px;

  --color: rgba(255, 255, 255, 0.7);
  --radius: 0px;

  /* Fixed height and width */
  /* height: var(--height); */
  /* width: var(--width); */

  /* Dynamic height and width */
  height: calc(var(--line-height) * 3 + var(--spacing) * 2);
  width: var(--width);

  display: flex;
  justify-content: end;
  align-items: center;
}

.burger-button__icon {
  position: relative;
  width: calc(var(--width) - 30%);
  height: var(--line-height);
  background-color: var(--color);
  border-radius: var(--radius);

  &::before,
  &::after {
    content: '';
    display: block;

    position: absolute;
    right: 0;

    width: var(--width);
    height: var(--line-height);

    border-radius: var(--radius);
    background-color: var(--color);
    transition: transform var(--time) ease-in, top var(--time) linear var(--time);
  }

  &::before {
    top: calc(-1 * (var(--line-height) + var(--spacing)));
  }

  &::after {
    top: calc(var(--line-height) + var(--spacing));
  }

  &--active {
    background-color: transparent;

    &::before,
    &::after {
      top: 0;
      transition: top var(--time) linear,
      transform var(--time) ease-in var(--time);
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }
}

.nav-icon.nav-icon--active::before,
.nav-icon.nav-icon--active::after {
  top: 0;
  transition: top var(--time) linear,
  transform var(--time) ease-in var(--time);
}

.nav-icon.nav-icon--active::before {
  transform: rotate(45deg);
}

.nav-icon.nav-icon--active::after {
  transform: rotate(-45deg);
}
</style>
