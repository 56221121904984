import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { useAuthStore } from '@/stores/auth';
import { Routes } from '@/types/routes';
import useToken from '@/composables/useToken';

const auth = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const authStore = useAuthStore();
  const { getToken, dropToken } = useToken();

  if (getToken() && !authStore.user) {
    await authStore.me();

    if (!authStore.user) {
      dropToken();
    }
  }

  if (to.name === 'auth-login' && authStore.user) {
    return next({ name: Routes.Home });
  }

  if (to.meta.requiresAuth && !authStore.user) {
    return next({ name: Routes.Login });
  }

  return next();
};

export default auth;
