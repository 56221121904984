<script setup lang="ts">
import { TournamentSystemName } from '@/constants/tournaments.const';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  theme?: 'main' | TournamentSystemName;
}>();

const { t } = useI18n();
</script>

<template>
  <div
    class="footer"
    :class="{
      'footer--main': props.theme === 'main',
      'footer--euro': props.theme === TournamentSystemName.Euro24,
      'footer--uefa': props.theme === TournamentSystemName.ChampionsLeague24Group,
    }"
  >
    <p class="container footer__text text-16">
      {{ t('footer')}}
      <a
        class="footer__link text-16"
        :class="{
          'footer__link--main': props.theme === 'main',
          'footer__link--euro': props.theme === TournamentSystemName.Euro24,
        }"
        href="https://lnk.reddy.team/invite/bQIsUh3jVMtkPDHvDcK~QVU6Ej4IGy1f"
        target="_blank"
      >
        reddyteam.com
      </a>
    </p>
  </div>
</template>

<style lang="scss" scoped>
.footer {
  width: 100%;
  padding: 12px 0;

  &--main {
    background: $clear-1;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
  }

  &--euro {
    border-top: 2px solid $yellow-120;
    background-color: $lilac-900;
  }

  &--uefa {
    border-top: 2px solid $yellow-100-fe;
    background-color: $lilac-900;

    .footer__link {
      color: $clear-60;

      @include hover-focus {
        color: $clear-80;
      }

      @include active {
        color: $clear-100;
      }
    }
  }
}

.footer__text {
  text-align: center;
  word-break: break-word;
  letter-spacing: 0.01em;
}

.footer__link {
  margin-top: 8px;
  display: block;
  text-decoration: underline;
  color: $clear-100;

  @include md {
    margin-top: 0;
    display: inline;
  }

  &--main {
    // empty
  }

  &--euro {
    @include md {
      color: $clear-60;
    }
  }
}
</style>
