import { ref, onMounted, onUnmounted } from 'vue';
import { Breakpoint, DeviceOrientation } from '@/types/responsive';

export function useResponsive() {
  const breakpoints = {
    tablet: 768,
    desktop: 1366,
  };

  const portraitMql = window.matchMedia('(orientation: portrait)');

  const getCurrentBreakpoint = (): Breakpoint => {
    const screenWidth = window.innerWidth;

    if (screenWidth < breakpoints.tablet) {
      return Breakpoint.Mobile;
    } if (screenWidth < breakpoints.desktop) {
      return Breakpoint.Tablet;
    } if (screenWidth >= breakpoints.desktop) {
      return Breakpoint.Desktop;
    }

    return Breakpoint.Mobile;
  };

  const currentBreakpoint = ref(getCurrentBreakpoint());
  const currentOrientation = ref(DeviceOrientation.Portrait);

  const updateBreakpoint = () => {
    currentBreakpoint.value = getCurrentBreakpoint();
  };

  const updateOrientation = async (event: MediaQueryListEvent) => {
    currentOrientation.value = event.matches
      ? DeviceOrientation.Portrait
      : DeviceOrientation.Landscape;
  };

  onMounted(() => {
    window.addEventListener('resize', updateBreakpoint);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', updateBreakpoint);
  });

  onMounted(() => {
    portraitMql.addEventListener('change', updateOrientation);
  });

  onUnmounted(() => {
    portraitMql.removeEventListener('change', updateOrientation);
  });

  return {
    currentBreakpoint,
    currentOrientation,
  };
}
