import { useCookies } from 'vue3-cookies';
import { ACCESS_TOKEN, COOKIE_OPTIONS } from '@/constants/auth';

const { cookies } = useCookies();

const useToken = () => {
  const getToken = (): string | null => cookies.get(ACCESS_TOKEN) ?? null;

  const saveToken = (token: string) => {
    try {
      const {
        expireTimes, path, domain, secure, sameSite,
      } = COOKIE_OPTIONS;
      cookies.set(ACCESS_TOKEN, token, expireTimes, path, domain, secure, sameSite);
    } catch {
      // eslint-disable-next-line no-console
      console.error('Ошибка сохранения токена');
    }
  };

  const dropToken = () => {
    try {
      cookies.remove(ACCESS_TOKEN);
    } catch {
      // eslint-disable-next-line no-console
      console.error('Ошибка удаления токена');
    }
  };

  return {
    getToken,
    saveToken,
    dropToken,
  };
};

export default useToken;
