import axios from 'axios';
import type {
  AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig,
} from 'axios';
import { StatusCodes } from 'http-status-codes';
import useToken from '@/composables/useToken';

const { getToken, dropToken } = useToken();

const apiWrapper: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
});

apiWrapper.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
  const authorizationToken = getToken();

  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${authorizationToken}`,
    },
  } as InternalAxiosRequestConfig;
});

apiWrapper.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError<{ error: string }>) => {
    if (error.response?.status === StatusCodes.UNAUTHORIZED) {
      dropToken();
      window.location.reload();
    }

    throw error;
  },
);

export default apiWrapper;
