import { defineStore } from 'pinia';
import api from '@/api';
import type { IError } from '@/types/api';
import { getError } from '@/helpers/errorHandler';
import useToken from '@/composables/useToken';
import { DivideStepEnum } from '@/types/user';
import type {
  IUser,
  ILoginData,
  ILoginResponse,
  ISendCodeData,
  ISendCodeResponse,
  IReddy,
} from '@/types/auth';

interface IState {
  user: IUser | null;
  reddy: IReddy;
}

const { saveToken } = useToken();

export const useAuthStore = defineStore('auth', {
  state: (): IState => ({
    user: null,
    reddy: {
      id: null,
      isSentCode: false,
    },
  }),
  actions: {
    async sendCode(payload: ISendCodeData): Promise<ISendCodeResponse | IError> {
      try {
        const { data } = await api.auth.sendCode(payload);

        if (data.data) {
          this.reddy.id = payload.reddy_id;
          this.reddy.isSentCode = true;
        }

        return data;
      } catch (error) {
        return getError(error);
      }
    },

    async login(payload: ILoginData): Promise<ILoginResponse | IError> {
      try {
        const { data } = await api.auth.login(payload);

        if (data.user && data.token) {
          this.user = data.user;
          saveToken(data.token);
        }

        return data;
      } catch (error) {
        return getError(error);
      }
    },

    async me() {
      try {
        const { data } = await api.auth.me();

        if (data.user) {
          this.user = data.user;
        }
      } catch (error) { /* empty */ }
    },
  },

  getters: {
    divideStep: (state) => {
      if (!state.user) return DivideStepEnum.Initial;

      const isInGroup = state.user?.groups.length > 0;
      const isInTeam = state.user?.baskets.length > 0;

      if (isInGroup && isInTeam) {
        return DivideStepEnum.InGroupAndInTeam;
      } if (isInGroup) {
        return DivideStepEnum.InGroup;
      }

      return DivideStepEnum.Initial;
    },
  },
});
