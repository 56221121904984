<template>
  <div class="layout">
    <div class="layout__content">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.layout {
  height: 100vh;
  height: 100dvh;
  display: flex;
  justify-content: center;
  overflow-y: auto;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image:
    url('@/assets/image/bg/auth-bg--mobile.png'),
    $bg-gradient;

  @include md {
    background-image:
      url('@/assets/image/bg/auth-bg--tablet.png'),
      $bg-gradient;
  }

  @include lg {
    background-image:
      url('@/assets/image/bg/auth-bg--dekstop.png'),
      $bg-gradient;
  }

  &__content {
    width: 100%;
  }
}
</style>
