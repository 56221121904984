import { defineStore } from 'pinia';
import { getError } from '@/helpers/errorHandler';
import api from '@/api';
import type { IError } from '@/types/api';
import type { AxiosResponse } from 'axios';
import { setTournamentTheme } from '@/helpers/set-tournament-theme';
import type { IGroup } from '@/types/groups';
import type { IBasket } from '@/types/baskets';
import { DivideStepEnum, type IUserDivideToGroupResponse } from '@/types/user';
import {
  type ITournament,
  type ITournamentFromApi,
  type ITournamentDistributionResult,
} from '@/types/tournaments';

interface IState {
  isLoading: boolean;
  isDivideLoading: boolean;
  data: null | ITournament;
}

export const useTournamentStore = defineStore('tournamentStore', {
  state: (): IState => ({
    isLoading: false,
    isDivideLoading: false,
    data: null,
  }),

  getters: {
    divideStep: (state): DivideStepEnum => {
      if (!state.data) return DivideStepEnum.Initial;

      const isInGroup = !!state.data.distributionResult.group;
      const isInTeam = !!state.data.distributionResult.team;

      if (isInGroup && isInTeam) {
        return DivideStepEnum.InGroupAndInTeam;
      } if (isInGroup) {
        return DivideStepEnum.InGroup;
      }

      return DivideStepEnum.Initial;
    },
  },

  actions: {
    async get(slug: string): Promise<ITournament | IError> {
      this.isLoading = true;
      this.data = null;

      try {
        const [tournamentResponse, groupAndTeamResponse]: [
          AxiosResponse<{ tournament: ITournamentFromApi }>,
          AxiosResponse<{ group: IGroup, basket: IBasket }>,
        ] = await Promise.all([
          api.tournament.get(slug),
          api.user.getGroupAndTeam(slug),
        ]);

        const tournamentData = tournamentResponse.data.tournament;
        const groupAndTeam = groupAndTeamResponse.data;

        this.data = {
          ...tournamentData,
          theme: setTournamentTheme(tournamentData.system_name),
          distributionResult: {
            group: groupAndTeam.group,
            team: groupAndTeam.basket,
          },
        };

        this.isLoading = false;

        return this.data;
      } catch (error) {
        this.isLoading = false;
        return Promise.reject(getError(error));
      }
    },

    async updateDistributionResult(slug?: string): Promise<ITournamentDistributionResult> {
      if (this.data) {
        try {
          const groupAndTeamResponse: AxiosResponse<{
            group: IGroup,
            basket: IBasket
          }> = await api.user.getGroupAndTeam(slug ?? this.data.slug);

          const groupAndTeam = groupAndTeamResponse.data;

          this.data.distributionResult.group = groupAndTeam.group;
          this.data.distributionResult.team = groupAndTeam.basket;

          return this.data.distributionResult;
        } catch (error) {
          return Promise.reject(getError(error));
        }
      } else {
        return {
          group: null,
          team: null,
        };
      }
    },

    async divideToGroup(id: number): Promise<IUserDivideToGroupResponse | IError> {
      try {
        this.isDivideLoading = true;
        // todo добавить типы для ответа - попросить сменить структуру ответа
        const { data }: AxiosResponse<any> = await api.user.divideToGroup({ tournament_id: id });

        this.isDivideLoading = false;

        return data;
      } catch (error) {
        this.isDivideLoading = false;
        return getError(error);
      }
    },
  },
});
