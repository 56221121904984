<script setup lang="ts">
import AppIcon from '@/components/App/Icon/AppIcon.vue';
import { computed } from 'vue';
import type { IconName } from '@/types/icons';

const props = defineProps<{
  type: 'info' | 'success' | 'error';
  title: string;
}>();

const iconName = computed<IconName>(() => {
  switch (props.type) {
    case 'success':
      return 'chevron';
    case 'error':
      return 'info';
    default:
      return 'info';
  }
});
</script>

<template>
  <div
    class="base-toast"
    :class="{
      'base-toast--success': props.type === 'success',
      'base-toast--error': props.type === 'error'
    }"
  >
    <p class="base-toast__title text-16-semibold">
      {{ props.title }}
    </p>

    <AppIcon
      class="base-toast__icon"
      :name="iconName"
    />
  </div>
</template>

<style lang="scss">
.base-toast {
  margin-bottom: 12px;
  width: calc(100vw - 40px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 16px;
  border-radius: 8px;
  background-color: $lilac-150;
  border: 1px solid $lilac-100;

  @include md {
    width: auto;
  }

  &--success {
    .base-toast__icon {
      color: $success-100;
    }
  }

  &--error {
    background-color: $error-bg;
    border: 1px solid $error-100;

    .base-toast__title {
      color: $error-100;
    }

    .base-toast__icon {
      color: $error-100;
    }
  }
}

.base-toast__title {
  color: $clear-100;
}

.base-toast__icon {
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  color: $clear-80;
}
</style>
