import apiWrapper from '@/helpers/apiWrapper';
import type {
  ITournamentRuleGetParams,
  ITournamentRuleGetResponse,
  ITournamentStatisticsGetResponse,
} from '@/types/tournament';

export default {
  get: (slug: string) => apiWrapper.get(`/api/v1/tournaments/${slug}`),
  getRules: (params: ITournamentRuleGetParams) => apiWrapper
    .get<ITournamentRuleGetResponse>('/api/v1/rules', { params }),
  getStatistics: (slug: string) => apiWrapper
    .get<ITournamentStatisticsGetResponse>(`/api/v1/tournaments/${slug}/statistics`),
};
