import { Routes } from '@/types/routes';
import TournamentLayout from '@/layouts/TournamentLayout.vue';
import DefaultLayout from '../layouts/DefaultLayout.vue';
import AuthLayout from '../layouts/AuthLayout.vue';

const routes = [
  {
    path: '/account',
    name: Routes.Account,
    component: () => import('../views/AccountView.vue'),
    meta: { layout: DefaultLayout, requiresAuth: true },
  },
  {
    path: '/auth/login',
    name: Routes.Login,
    component: () => import('../views/auth/LoginView.vue'),
    meta: { layout: AuthLayout },
  },
  {
    path: '/',
    name: Routes.Home,
    component: () => import('../views/TournamentsView.vue'),
    meta: { layout: DefaultLayout, requiresAuth: true },
  },
  {
    path: '/top',
    name: Routes.Top,
    component: () => import('../views/TopView.vue'),
    meta: { layout: DefaultLayout, requiresAuth: true },
  },
  {
    path: '/faq',
    name: Routes.FAQ,
    component: () => import('../views/FAQView.vue'),
    meta: { layout: DefaultLayout, requiresAuth: true },
  },
  {
    path: '/join-team/:id',
    name: Routes.JoinTeam,
    component: () => import('../views/JoinTeamView.vue'),
    meta: { layout: DefaultLayout, requiresAuth: true },
  },
  {
    path: '/tournament/:slug',
    name: Routes.Tournament,
    component: () => import('../views/tournament/TournamentView.vue'),
    meta: { layout: TournamentLayout, requiresAuth: true },
  },
  {
    path: '/tournament/:slug/rating',
    name: Routes.TournamentRatings,
    component: () => import('../views/tournament/TournamentRatingsView.vue'),
    meta: { layout: TournamentLayout, requiresAuth: true },
  },
  {
    path: '/tournament/:slug/stats',
    name: Routes.TournamentStats,
    component: () => import('../views/tournament/TournamentStatsView.vue'),
    meta: { layout: TournamentLayout, requiresAuth: true },
  },
  {
    path: '/tournament/:slug/rules',
    name: Routes.TournamentRules,
    component: () => import('../views/tournament/TournamentRulesView.vue'),
    meta: { layout: TournamentLayout, requiresAuth: true },
  },
  {
    path: '/:pathMatch(.*)*',
    name: Routes.NotFound,
    component: () => import('../views/NotFoundView.vue'),
    meta: { layout: DefaultLayout, requiresAuth: true },
  },
];

export default routes;
