<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
import type { IconName } from '@/types/icons';

interface Props {
  name: IconName;
}
const props = defineProps<Props>();

const icon = defineAsyncComponent(() => import(`../../../assets/icons/${props.name}.svg`));
</script>

<template>
  <component :is="icon" width="24" height="24" />
</template>
